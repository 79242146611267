import { FC, useState, useRef, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import SettingsIcon from "@mui/icons-material/Settings";
import SummarizeIcon from "@mui/icons-material/Summarize";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DashboardIcon from "@mui/icons-material/Dashboard";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import PaymentsIcon from "@mui/icons-material/Payments";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import HelpIcon from "@mui/icons-material/Help";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";

import useProfile from "../../../apis/auth/useProfile";
import { getIsAdmin, isIndividualUser, isFirmAdmin } from "../../../utills";
import mini_Logo_img from "../../../assets/images/Brand-logo-mini.svg";
import Logo_img from "../../../assets/images/Brand-logo.svg";
import FilingHistoryDialog from "../../filingHistoryDialog/FilingHistoryDialog";
import RequestDocumentDialog from "../../requestDocumentDialog/RequestDocumentDialog";

interface SidebarProps {
  isSideBarOpen: boolean;
  setIsSideBarOpen: (isSideBarOpen: boolean) => void;
}

const Sidebar: FC<SidebarProps> = ({ isSideBarOpen, setIsSideBarOpen }) => {
  const profile = useProfile();
  const navigate = useNavigate();
  const [activeMenuItem, setActiveMenuItem] = useState("");
  const [openFiling, setopenFiling] = useState(true);
  const [isHovering, setIsHovering] = useState(false);
  const [isFilingHistoryModalOpen, setIsFilingHistoryModalOpen] =
    useState(false);
  const [isRequestDocumentModalOpen, setIsRequestDocumentModalOpen] =
    useState(false);
  const [expandSections, setExpandSections] = useState({
    admin: true,
    workspace: true,
    payment: true,
    reports: true,
    help: true
  })

  const sidebarRef = useRef<HTMLDivElement>(null);

  const openFilingHistoryModal = useCallback(() => {
    setIsFilingHistoryModalOpen(true);
  }, []);

  const closeFilingHistoryModal = useCallback(() => {
    setIsFilingHistoryModalOpen(false);
  }, []);

  const openRequestDocumentModal = useCallback(() => {
    setIsRequestDocumentModalOpen(true);
  }, []);

  const closeRequestDocumentModal = useCallback(() => {
    setIsRequestDocumentModalOpen(false);
  }, []);

  const onMenuItemClick = useCallback((menu: keyof typeof expandSections) => {
    setExpandSections((prev) => ({
      ...prev,
      [menu]: !prev[menu],
    }));

    setActiveMenuItem((prevState) => (prevState === menu ? "" : menu));
  }, []);

  const gotoFilingHistory = useCallback(() => {
    navigate("/filingHistory");
  }, [navigate]);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
    //setOpen(false);
  };

  const onLogoClick = useCallback(() => {
    if (profile.data) {
      const isAdmin = getIsAdmin();
      if (isAdmin === "admin") {
        navigate("/admin/pendingrequests");
      } else {
        navigate("/dashboard");
      }
    } else {
      navigate("/");
    }
  }, [profile.data, navigate]);

  useEffect(() => {

    const mouseDownListener = (event: any) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        window.outerWidth < 1024
      ) {
        setIsSideBarOpen(true);
      }
    };

    document.addEventListener("mousedown", mouseDownListener);

    return () => {
      document.removeEventListener("mousedown", mouseDownListener);
    };
    // eslint-disable-next-line
  }, []);

  const isAdmin = getIsAdmin();
  if (isAdmin) {
    return (
      <div
        className={`sidebar_col ${isSideBarOpen ? "" : "toggle"} ${
          isHovering ? "panel_hover" : ""
        }`}
        ref={sidebarRef}
      >
        <div className="brand_logo text-center cursor-pointer">
          <span
            role="button"
            className="brand-logo-main cursor-pointer"
            onClick={onLogoClick}
            tabIndex={0}
          >
            <img
              src={Logo_img}
              alt="I2File Your electronics Path for filing cases"
            />
          </span>
          <span
            role="button"
            className="brand-logo-mini cursor-pointer"
            onClick={onLogoClick}
            tabIndex={0}
          >
            <img
              src={mini_Logo_img}
              alt="I2File Your electronics Path for filing cases"
            />
          </span>
        </div>

        <div
          className="sidebar_col_list"
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          <MenuList role="menubar">
            <MenuItem
              tabIndex={0}
              className="sidebar_col_item"
              onClick={onMenuItemClick.bind(this, "workspace")}
            >
              <ListItemIcon className="sidebar_col_icon">
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText
                className="sidebar_col_link"
                primary="Admin Access"
              />
              {expandSections.workspace ? (
                <ExpandLess className="sidebar_col_arrow" />
              ) : (
                <ExpandMore className="sidebar_col_arrow" />
              )}
            </MenuItem>

            <Collapse
              className="sidebar_col_item_sub"
              in={activeMenuItem === "workspace"}
              timeout="auto"
              unmountOnExit
            >
              <MenuList role="menubar" disablePadding>
                <MenuItem
                  tabIndex={0}
                  className="sidebar_col_item"
                  onClick={() => navigate("/admin/pendingrequests")}
                >
                  <ListItemText
                    className="sidebar_col_link"
                    primary="Pending Requests"
                  />
                </MenuItem>
                <MenuItem
                  tabIndex={0}
                  className="sidebar_col_item"
                  onClick={() => navigate("/admin/usernotification")}
                >
                  <ListItemText
                    className="sidebar_col_link"
                    primary="User Notification"
                  />
                </MenuItem>
                <MenuItem
                  tabIndex={0}
                  className="sidebar_col_item"
                  onClick={() => navigate("/admin/allusers")}
                >
                  <ListItemText
                    className="sidebar_col_link"
                    primary="All Users"
                  />
                </MenuItem>
                <MenuItem
                  tabIndex={0}
                  className="sidebar_col_item"
                  onClick={() => navigate("/admin/countyadmin")}
                >
                  <ListItemText
                    className="sidebar_col_link"
                    primary="County Admin"
                  />
                </MenuItem>
              </MenuList>
            </Collapse>

            {!isIndividualUser() && (
              <>
                <MenuItem
                  tabIndex={0}
                  className="sidebar_col_item"
                  onClick={onMenuItemClick.bind(this, "reports")}
                >
                  <ListItemIcon className="sidebar_col_icon">
                    <SummarizeIcon />
                  </ListItemIcon>
                  <ListItemText
                    className="sidebar_col_link"
                    primary="Reports"
                  />
                  {activeMenuItem === "reports" ? (
                    <ExpandLess className="sidebar_col_arrow" />
                  ) : (
                    <ExpandMore className="sidebar_col_arrow" />
                  )}
                </MenuItem>
                <Collapse
                  className="sidebar_col_item_sub"
                  in={activeMenuItem === "reports"}
                  timeout="auto"
                  unmountOnExit
                >
                  <MenuList role="menubar" disablePadding>
                    <MenuItem
                      tabIndex={0}
                      className="sidebar_col_item"
                      onClick={() => navigate("/admin/transactionreport")}
                    >
                      <ListItemText
                        className="sidebar_col_link"
                        primary="Transaction Report"
                      />
                    </MenuItem>
                  </MenuList>
                </Collapse>
              </>
            )}

            <MenuItem
              tabIndex={0}
              className="sidebar_col_item"
              onClick={onMenuItemClick.bind(this, "payment")}
            >
              <ListItemIcon className="sidebar_col_icon">
                <FileUploadIcon />
              </ListItemIcon>
              <ListItemText
                className="sidebar_col_link"
                primary="Tyler Payment"
              />
              {activeMenuItem === "payment" ? (
                <ExpandLess className="sidebar_col_arrow" />
              ) : (
                <ExpandMore className="sidebar_col_arrow" />
              )}
            </MenuItem>

            <Collapse
              className="sidebar_col_item_sub"
              in={activeMenuItem === "payment"}
              timeout="auto"
              unmountOnExit
            >
              <MenuList role="menubar" disablePadding>
                <MenuItem
                  tabIndex={0}
                  className="sidebar_col_item"
                  onClick={() => navigate("/admin/importpayment")}
                >
                  <ListItemText
                    className="sidebar_col_link"
                    primary="Import Payment"
                  />
                </MenuItem>
              </MenuList>
            </Collapse>
          </MenuList>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`sidebar_col ${isSideBarOpen ? "" : "toggle"} ${
        isHovering ? "panel_hover" : ""
      }`}
      ref={sidebarRef}
    >
      <div className="brand_logo text-center cursor-pointer">
        <span
          role="button"
          className="brand-logo-main cursor-pointer"
          onClick={onLogoClick}
          tabIndex={0}
        >
          <img
            src={Logo_img}
            alt="I2File Your electronics Path for filing cases"
          />
        </span>
        <span
          role="button"
          className="brand-logo-mini cursor-pointer"
          onClick={onLogoClick}
        >
          <img
            src={mini_Logo_img}
            alt="I2File Your electronics Path for filing cases"
          />
        </span>
      </div>

      <div
        className="sidebar_col_list"
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        <List component="nav" aria-label="vertical menubar">
          <MenuList role="menubar">
            <MenuItem
              tabIndex={0}
              aria-expanded={activeMenuItem === "workspace" ? "true" : "false"}
              className="sidebar_col_item"
              onClick={onMenuItemClick.bind(this, "workspace")}
            >
              <ListItemIcon className="sidebar_col_icon">
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText className="sidebar_col_link" primary="Workspace" />
              {expandSections.workspace ? (
                <ExpandLess className="sidebar_col_arrow" />
              ) : (
                <ExpandMore className="sidebar_col_arrow" />
              )}
            </MenuItem>

            <Collapse
              className="sidebar_col_item_sub"
              in={expandSections.workspace}
              timeout="auto"
              unmountOnExit
            >
              <MenuList role="menubar" disablePadding>
                <MenuItem
                  tabIndex={0}
                  className="sidebar_col_item"
                  onClick={() => navigate("/dashboard")}
                >
                  <ListItemText
                    className="sidebar_col_link"
                    primary="Dashboard"
                  />
                </MenuItem>
                <MenuItem
                  tabIndex={0}
                  className="sidebar_col_item"
                  onClick={() => setopenFiling((prevValue) => !prevValue)}
                  aria-expanded={openFiling ? "true" : "false"}
                  aria-label="submenubar"
                >
                  <ListItemText
                    className="sidebar_col_link"
                    primary="Submit Filing"
                  />
                  {openFiling ? (
                    <ExpandLess className="sidebar_col_arrow" />
                  ) : (
                    <ExpandMore className="sidebar_col_arrow" />
                  )}
                </MenuItem>

                <Collapse
                  className="sidebar_col_item_sub sidebar_col_item_submenu"
                  in={openFiling}
                  timeout="auto"
                  unmountOnExit
                >
                  <MenuList role="menubar" disablePadding>
                    <MenuItem
                      tabIndex={0}
                      className="sidebar_col_item"
                      onClick={() => navigate("/newcase")}
                    >
                      <ListItemText
                        className="sidebar_col_link"
                        primary="Start a New Case"
                      />
                    </MenuItem>
                    <MenuItem
                      tabIndex={0}
                      className="sidebar_col_item"
                      onClick={() => navigate("/existingcase")}
                    >
                      <ListItemText
                        className="sidebar_col_link"
                        primary="File on Existing Case"
                      />
                    </MenuItem>
                  </MenuList>
                </Collapse>

                <MenuItem
                  tabIndex={0}
                  className="sidebar_col_item"
                  aria-haspopup="dialog"
                  onClick={openFilingHistoryModal}
                >
                  <ListItemText
                    className="sidebar_col_link"
                    primary="Filing History"
                  />
                </MenuItem>
                <MenuItem
                  tabIndex={0}
                  className="sidebar_col_item"
                  onClick={() => navigate("/myDrafts")}
                >
                  <ListItemText
                    className="sidebar_col_link"
                    primary="My Drafts"
                  />
                </MenuItem>
                <MenuItem
                  tabIndex={0}
                  className="sidebar_col_item"
                  onClick={openRequestDocumentModal}
                >
                  <ListItemText
                    className="sidebar_col_link"
                    primary="Document Request"
                  />
                </MenuItem>
                <MenuItem
                  tabIndex={0}
                  className="sidebar_col_item"
                  onClick={() => navigate("/serviceContacts")}
                >
                  <ListItemText
                    className="sidebar_col_link"
                    primary="Service Contacts"
                  />
                </MenuItem>
                <MenuItem
                  tabIndex={0}
                  className="sidebar_col_item"
                  onClick={() => navigate("/searchCases")}
                >
                  <ListItemText
                    className="sidebar_col_link"
                    primary="Search Cases"
                  />
                </MenuItem>
              </MenuList>
            </Collapse>

            {!isIndividualUser() && isFirmAdmin() && (
              <>
                <MenuItem
                  tabIndex={0}
                  className="sidebar_col_item"
                  onClick={onMenuItemClick.bind(this, "admin")}
                  aria-expanded={activeMenuItem === "admin" ? "true" : "false"}
                >
                  <ListItemIcon className="sidebar_col_icon">
                    <InsertLinkIcon />
                  </ListItemIcon>
                  <ListItemText
                    className="sidebar_col_link"
                    primary="Admin Links"
                  />
                   {activeMenuItem === "admin" ? (
                    <ExpandLess className="sidebar_col_arrow" />
                  ) : (
                    <ExpandMore className="sidebar_col_arrow" />
                  )}
                </MenuItem>
                <Collapse
                  className="sidebar_col_item_sub"
                  in={activeMenuItem === "admin"}
                  timeout="auto"
                  unmountOnExit
                >
                  <MenuList role="menubar" disablePadding>
                    <MenuItem
                      tabIndex={0}
                      className="sidebar_col_item"
                      onClick={() => navigate("/firmInformation")}
                    >
                      <ListItemText
                        className="sidebar_col_link"
                        primary="Firm Information"
                      />
                    </MenuItem>
                    <MenuItem
                      tabIndex={0}
                      className="sidebar_col_item"
                      onClick={() => navigate("/firm/users")}
                    >
                      <ListItemText
                        className="sidebar_col_link"
                        primary="Firm Users"
                      />
                    </MenuItem>
                    <MenuItem
                      tabIndex={0}
                      className="sidebar_col_item"
                      onClick={() => navigate("/attorneys")}
                    >
                      <ListItemText
                        className="sidebar_col_link"
                        primary="Attorneys"
                      />
                    </MenuItem>
                  </MenuList>
                </Collapse>
              </>
            )}

            {isFirmAdmin() && (
              <>
                <MenuItem
                  tabIndex={0}
                  className="sidebar_col_item"
                  onClick={onMenuItemClick.bind(this, "payment")}
                  aria-expanded={
                    activeMenuItem === "payment" ? "true" : "false"
                  }
                >
                  <ListItemIcon className="sidebar_col_icon">
                    <PaymentsIcon />
                  </ListItemIcon>
                  <ListItemText
                    className="sidebar_col_link"
                    primary="Payment Info"
                  />
                  {activeMenuItem === "payment" ? (
                    <ExpandLess className="sidebar_col_arrow" />
                  ) : (
                    <ExpandMore className="sidebar_col_arrow" />
                  )}
                </MenuItem>

                <Collapse
                  className="sidebar_col_item_sub"
                  in={activeMenuItem === "payment"}
                  timeout="auto"
                  unmountOnExit
                >
                  <MenuList role="menubar" disablePadding>
                    <MenuItem
                      tabIndex={0}
                      className="sidebar_col_item"
                      onClick={() => navigate("/paymentAccounts")}
                    >
                      <ListItemText
                        className="sidebar_col_link"
                        primary="Payment Accounts"
                      />
                    </MenuItem>
                  </MenuList>
                </Collapse>
              </>
            )}

            <MenuItem
              tabIndex={0}
              className="sidebar_col_item"
              onClick={onMenuItemClick.bind(this, "reports")}
              aria-expanded={activeMenuItem === "reports" ? "true" : "false"}
            >
              <ListItemIcon className="sidebar_col_icon">
                <AutoGraphIcon />
              </ListItemIcon>
              <ListItemText className="sidebar_col_link" primary="Reports" />
              {activeMenuItem === "reports" ? (
                <ExpandLess className="sidebar_col_arrow" />
              ) : (
                <ExpandMore className="sidebar_col_arrow" />
              )}
            </MenuItem>

            <Collapse
              className="sidebar_col_item_sub"
              in={activeMenuItem === "reports"}
              timeout="auto"
              unmountOnExit
            >
              <MenuList role="menubar" disablePadding>
                <MenuItem
                  tabIndex={0}
                  className="sidebar_col_item"
                  onClick={() => navigate("/transactionReport")}
                >
                  <ListItemText
                    className="sidebar_col_link"
                    primary="Transaction Report"
                  />
                </MenuItem>
              </MenuList>
            </Collapse>

            <MenuItem
              tabIndex={0}
              className="sidebar_col_item"
              onClick={onMenuItemClick.bind(this, "help")}
              aria-expanded={activeMenuItem === "help" ? "true" : "false"}
            >
              <ListItemIcon className="sidebar_col_icon">
                <HelpIcon />
              </ListItemIcon>
              <ListItemText className="sidebar_col_link" primary="Help" />
              {activeMenuItem === "help" ? (
                <ExpandLess className="sidebar_col_arrow" />
              ) : (
                <ExpandMore className="sidebar_col_arrow" />
              )}
            </MenuItem>
          </MenuList>

          <Collapse
            className="sidebar_col_item_sub"
            in={activeMenuItem === "help"}
            timeout="auto"
            unmountOnExit
          >
            <MenuList role="menubar" disablePadding>
              <MenuItem tabIndex={0} className="sidebar_col_item">
                <ListItemText
                  className="sidebar_col_link"
                  primary="Support"
                  onClick={() => navigate("/support")}
                />
              </MenuItem>
              <MenuItem
                tabIndex={0}
                className="sidebar_col_item"
                target="_blank"
                component="a"
                href="assets/pdf/I2File-User_Manual_v1.0.pdf"
                aria-label="I2File User Manual"
              >
                <ListItemText
                  className="sidebar_col_link"
                  primary="User Manual"
                />
              </MenuItem>
            </MenuList>
          </Collapse>
        </List>
      </div>

      <FilingHistoryDialog
        isOpen={isFilingHistoryModalOpen}
        onClose={closeFilingHistoryModal}
        gotoFilingHistory={gotoFilingHistory}
      />

      <RequestDocumentDialog
        isOpen={isRequestDocumentModalOpen}
        onClose={closeRequestDocumentModal}
      />
    </div>
  );
};

export default Sidebar;
