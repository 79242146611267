import { useCallback, useMemo, useState } from "react";
import EditIcon from "@mui/icons-material/EditOutlined";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import { GridRenderCellParams, GridColDef } from "@mui/x-data-grid/models";

import Page from "../../../components/page";
import GridTable from "../../../components/grid";
import CreateEditUser from "./CreateEditUser";
import DeleteUser from "./DeleteUser";
import queryClient from "../../../queryClient";
import { GET_FIRM_USERS } from "../../../apis/URL";
import { IFirmUser } from "../../../interfaces";
import useGrid from '../../../components/grid/useGrid'; 


const FirmUsers = () => {
  const [isCreateEditModalOpen, setIsCreateEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<IFirmUser | undefined>();

  const onCreate = useCallback(() => {
    setIsCreateEditModalOpen(true);
  }, []);

  const onEdit = useCallback((user: IFirmUser) => {
    setSelectedUser(user);
    setIsCreateEditModalOpen(true);
  }, []);

  const onDelete = useCallback((user: IFirmUser) => {
    setSelectedUser(user);
    setIsDeleteModalOpen(true);
  }, []);

  const onCreateEditModalClose = useCallback((shouldUpdateGrid?: boolean) => {
    if (shouldUpdateGrid) queryClient.refetchQueries(["grid", GET_FIRM_USERS]);

    setSelectedUser(undefined);
    setIsCreateEditModalOpen(false);
  }, []);

  const onDeleteModalClose = useCallback((shouldUpdateGrid?: boolean) => {
    if (shouldUpdateGrid) queryClient.refetchQueries(["grid", GET_FIRM_USERS]);

    setSelectedUser(undefined);
    setIsDeleteModalOpen(false);
  }, []);

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: "firstName",
        headerName: "First Name",
        width: 300,
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "lastName",
        headerName: "Last Name",
        width: 300,
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
      },
      {
        field: "email",
        headerName: "Email Address",
        width: 350,
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          return (
               <span style={{ width: "100%"}} id={params.row.userID} title={`Email ${params.row.email}`}>
                {params.row.email}
              </span>
          );
        },
      },
      {
        field: "role",
        headerName: "Role",
        width: 250,
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          const roles = params.row?.role
            ?.map((role: { roleName: string }) =>
              role.roleName === "FILER" ? "Filer" : "Firm Admin"
            )
            ?.join(", ");

            return params.row.isActive ? (
              <span>{roles}</span>
            ) : (
              <span
                style={{ width: "100%" }}
                title={`Inactive User, Role(s): ${roles}`}
              >
                {roles}
              </span>
            );
        },
      },
      {
        field: "status",
        headerName: "Status",
        width: 150,
        sortable: true,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {     
            let label =  "";
            if (params.row.isLockedOut) {
              label = "Locked";
            }else if (params.row.isActivated === false) {
              label = "Unverified";
            }else{
              label = "Active"
            }

            if (params.row.isActive === false) {
              return (
            <span style={{ width: "100%"}} id={params.row.userID} title={`Inactive User`}>
            {label}
          </span>
              )
            }else{
              return label
            }
          
        },
      },
      {
        field: "action",
        headerName: "Action",
        width: 120,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <div className="dataGridactionIcons">
              <Tooltip title="Edit Firm User">
                <IconButton
                  aria-label="edit firm user"
                  onClick={() => onEdit(params.row)}
                  aria-describedby={params.row.userID}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Discard">
                <IconButton
                  aria-label="delete"
                  onClick={() => onDelete(params.row)}
                  aria-describedby={params.row.userID}
                >
                  <span className="material-symbols-outlined datagrid-cancel">
                    delete
                  </span>
                </IconButton>
              </Tooltip>
            </div>
          );
        },
      },
    ],
    [onDelete, onEdit]
  );

  const getRowId = useCallback((row: any) => row.userID, []);

  const url = GET_FIRM_USERS; 

  const { data, isLoading: apiIsLoading } = useGrid(url); 

  const filteredRows = useMemo(() => {
    return data?.filter((row: any) => row.isActive === true) || [];
  }, [data]);

  return (
    <Page>
      <div className="content-wrapper">
        <h2 className="dash-title-h2 mb-4">Manage Users for Your Firm</h2>

        <div className="themeDataTable">
          <GridTable
            columns={columns}
            rows = {filteredRows}
            searchKeys={["firstName", "lastName", "email"]}
            showSearch
            hideFooter={false}
            getRowId={getRowId}
            isLoading={apiIsLoading}
            getRowClassName={(params) =>
              params.row.isActivated ? "" : "inactive-row"
            }
            action={
              <Button
                size="large"
                variant="contained"
                className="btn primary-button"
                onClick={onCreate}
              >
                Add Firm User
              </Button>
            }
          />
        </div>
      </div>

      {isCreateEditModalOpen && (
        <CreateEditUser
          key={selectedUser?.userID}
          user={selectedUser}
          isOpen={isCreateEditModalOpen}
          onClose={onCreateEditModalClose}
        />
      )}

      <DeleteUser
        user={selectedUser}
        isOpen={isDeleteModalOpen}
        onClose={onDeleteModalClose}
      />
    </Page>
  );
};

export default FirmUsers;
