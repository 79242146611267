import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import InputAdornment from "@mui/material/InputAdornment";
import Input from "@mui/material/Input";
import Typography from "@mui/material/Typography";
import styled from "@mui/material/styles/styled";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";

import Header from "../../landingPage/Header";
import useSupport from "../../../apis/help/support";
import useProfile from "../../../apis/auth/useProfile";
import { NotificationContext } from "../../../context/NotificationContext";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: number) => ({
  id: `vertical-tab-${index}`,
  "aria-controls": `vertical-tabpanel-${index}`,
});

const Support = () => {
  const { createNotification } = useContext(NotificationContext);
  const profile = useProfile();
  const support = useSupport();
  const [activeTab, setActiveTab] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeQuestion, setActiveQuestion] = useState<string | boolean>(false);

  const onAccordianChange = useCallback((questionId: string) => {
    return (event: React.SyntheticEvent, newExpanded: boolean) => {
      setActiveQuestion(newExpanded ? questionId : false);
    };
  }, []);

  const handleTabChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setActiveTab(newValue);
    },
    []
  );

  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value.toLowerCase());
    },
    []
  );

  const sanitizeHtml = useCallback((htmlString: string) => {
    return {
      __html: htmlString.replace(/\\+/g, "/"),
    };
  }, []);

  const filteredFAQs = useMemo(() => {
    if (!support.data) return [];

    return support.data.filter((faq) =>
      faq.questionText.toLowerCase().includes(searchTerm)
    );
  }, [support.data, searchTerm]);

  useEffect(() => {
    if (support.isError) {
      createNotification({
        message:
          support.error.response?.data?.userMessage ||
          "An Error occurred while getting FAQs!",
        type: "error",
        title: "Support Notification",
      });
    }
    // eslint-disable-next-line
  }, [support.isError]);

  return (
    <div>
      <Header isUserLoggedIn={Boolean(profile.data)}></Header>
      <div className="content-wrapper">
        <div className="essentials-support-row mt-4">
          <Grid container spacing={4}>
            <Grid item xs={12} md={9} lg={10}>
              <Item className="remove-item-css">
                <div className="text-center">
                  <h2 className="red-pastel mb-2">Essentials Support</h2>
                  <Input
                    id="search-topic"
                    className="material-input w-100 mb-4"
                    placeholder="Enter a Search topic"
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                    inputProps={{
                      "aria-label": "Enter a Search topic",
                    }}
                    onChange={handleSearch}
                  />
                </div>

                <Box
                  className="vertical-tabs text-left"
                  sx={{ flexGrow: 1, display: "flex" }}
                >
                  <div className="vertical-tabs-left">
                    <Tabs
                      orientation="vertical"
                      variant="scrollable"
                      value={activeTab}
                      onChange={handleTabChange}
                      aria-label="Essentials Support tabs"
                      sx={{ borderRight: 1, borderColor: "divider" }}
                    >
                      <Tab
                        className="vertical-tabs-col d-flex align-items-start"
                        label="Filings"
                        {...a11yProps(0)}
                      />
                      <Tab
                        className="vertical-tabs-col d-flex align-items-start"
                        label="Notification"
                        {...a11yProps(1)}
                      />
                      <Tab
                        className="vertical-tabs-col d-flex align-items-start"
                        label="User Roles"
                        {...a11yProps(2)}
                      />
                      <Tab
                        className="vertical-tabs-col d-flex align-items-start"
                        label="Error Codes"
                        {...a11yProps(3)}
                      />
                      <Tab
                        className="vertical-tabs-col d-flex align-items-start"
                        label="Payment Account"
                        {...a11yProps(4)}
                      />
					  <Tab
                        className="vertical-tabs-col d-flex align-items-start"
                        label="Document Request"
                        {...a11yProps(5)}
                      />
                    </Tabs>
                  </div>
                  <div className="vertical-tabs-col-right">
                    {filteredFAQs?.map((faq, index) => (
                      <TabPanel value={activeTab} index={faq.categoryId - 1}>
                        <div className="vertical-tabs-dtl">
                          <Accordion
                            key={faq.questionId}
                            slotProps={{ transition: { timeout: 0 } }}
                            expanded={activeQuestion === faq.questionId}
                            onChange={onAccordianChange(faq.questionId)}
                            className="accordion-slide remove-item-css"
                          >
                            <AccordionSummary
                              expandIcon={
                                <ExpandMoreIcon className="ExpandMoreIcon" />
                              }
                              aria-controls={`panel${index}bh-content`}
                              id={`panel${index}bh-header`}
                              className="accordion-title"
                            >
                              <h4>
                                <div
                                  dangerouslySetInnerHTML={sanitizeHtml(
                                    faq.questionText
                                  )}
                                />
                              </h4>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>
                                <div
                                  dangerouslySetInnerHTML={sanitizeHtml(
                                    faq.answerText
                                  )}
                                />
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </div>
                      </TabPanel>
                    ))}
                  </div>
                </Box>
              </Item>
            </Grid>

            <Grid item xs={12} md={3} lg={2}>
              <Item className="remove-item-css">
                <Button
                  href="assets/pdf/I2File-User_Manual_v1.0.pdf"
                  className="btn primary-button mb-3 w-100"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="I2File User Manual"
                >
                  User Manual
                </Button>
                <div className="tabs-supportmsg ">
                  <span>
                    In case you are not able to find answers to your question,{" "}
                    <br />
                    <a
                      href="http://support.conscisys.com/"
                      target="_blank"
                      rel="noreferrer"
                      aria-label="Conscisys Support portal"
                    >
                      <u>Click Here</u>{" "}
                    </a>
                    to raise a support ticket
                  </span>
                </div>
              </Item>
            </Grid>
          </Grid>
        </div>
      </div>
      <footer className="dash-footer text-center p-2">
        <span className="d-sm-inline-block">
          Copyright © 2007 - {new Date().getFullYear()} Conscisys Corporation
          <a
            href="https://www.conscisys.com"
            target="_blank"
            rel="noreferrer"
            aria-label="Conscisys Portal"
          >
            Powered by Conscisys{" "}
          </a>
        </span>
      </footer>
    </div>
  );
};

export default Support;
